<template>
  <svg v-bind="attrs">
    <circle
      cx="16"
      cy="16"
      r="15.5"
      fill="transparent"
      stroke="transparent"
    />
    <circle
      cx="16"
      cy="11"
      r="3.5"
      fill="transparent"
      stroke="currentColor"
    />
    <path
      d="M9.5 22.8571C9.5 19.3462 12.3462 16.5 15.8571 16.5H16.1429C19.6538 16.5 22.5 19.3462 22.5 22.8571C22.5 23.2122 22.2122 23.5 21.8571 23.5H10.1429C9.78782 23.5 9.5 23.2122 9.5 22.8571Z"
      fill="transparent"
      stroke="currentColor"
    />
  </svg>
</template>

<script lang="ts" setup>
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 32, height: 32 });
</script>
