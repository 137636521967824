<template>
  <div
    class="spinner"
    :class="{ spinner__inline: inline }"
  >
    <div
      class="spinner__circle"
      :class="{ spinner__circle_inline: inline }"
    />
    <div v-if="!inline">
      {{ t("loader") }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

interface Props {
  inline?: boolean;
}

withDefaults(defineProps<Props>(), {
  inline: false,
});
</script>

<style scoped lang="scss">
@import "@/assets/style/variables.scss";

.spinner {
  padding: 10px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.spinner__inline {
  display: inline-flex;
  padding: 0;
}

.spinner__circle {
  position: relative;
  margin-bottom: 20px;
  animation: spinner-keyframes 1s linear infinite;
  background: conic-gradient(
    from 180deg at 50% 50%,
    rgba(196, 196, 196, 0) -26.18deg,
    rgba(19, 123, 196, 0) 10.36deg,
    #2e3192 269.37deg,
    rgba(196, 196, 196, 0) 333.82deg,
    rgba(19, 123, 196, 0) 370.36deg
  );
  border-radius: 50%;
  // border-top: 1px solid $brand-color;
  height: $spinner-circle-size;
  width: $spinner-circle-size;

  &:after {
    content: "";
    width: 85%;
    height: 85%;
    position: absolute;
    background: var(--color-background);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }

  &_inline {
    box-sizing: border-box;
    height: $spinner-circle-size_inline;
    margin: 0;
    width: $spinner-circle-size_inline;
  }
}

@keyframes spinner-keyframes {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
