<template>
  <nav class="nav">
    <template
      v-for="(element, index) in menu"
      :key="`navLink-${index}`"
    >
      <router-link
        v-if="!element.baseUrlLink"
        :to="element.link"
        class="nav__link"
      >
        {{ t(element.title) }}
      </router-link>
      <a
        v-else
        :href="element.baseUrlLink"
        class="nav__link"
      >
        {{ t(element.title) }}
      </a>
    </template>
  </nav>
</template>

<script setup lang="ts">
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { mainMenu, MenuItem } from '@/constants/menus';

const store = useStore();
const { t } = useI18n();

const userRole = computed<string>(() => store.getters.role);

const menu = computed<Array<MenuItem>>(
  () => mainMenu.filter(
    (menuLink) => menuLink.permissions.includes(userRole.value)
  )
);
</script>

<style scoped lang="scss">
@import "@/assets/style/include";

.nav {
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  overflow-y: auto;
}

.nav__link {
  font-family: "PT Sans", sans-serif;
  font-size: 15px;
  font-weight: 400;
  align-items: center;
  background: $color-transparent;
  box-shadow: inset 0 -3px 0 $color-transparent;
  display: inline-flex;
  height: $header-height;
  padding: 22px 20px;
  transition: box-shadow $transition-speed, background $transition-speed;

  @include hover-focus() {
    background: $color-white-darkest;
    box-shadow: inset 0 0 0 3px $color-cyan-menu;
    outline: none;
  }

  @include media-breakpoint-down("sm") {
    height: $header-height-mobile;
    padding: 22px 8px;
  }
}

.nav__link.active {
  background: $color-white-darkest;
  box-shadow: inset 0 -3px 0 $color-cyan-menu;
  font-weight: 700;
}

.nav__link.active.exact {
  cursor: default;
}
</style>
