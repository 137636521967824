<template>
  <ElRow
    justify="center"
    class="general-table file-table__header-row"
  >
    <ElCol
      :span="1"
      class="file-table__header-col"
    >
      <div class="file-table__header-title file-table__header-title_align-right">
        &nbsp;
      </div>
    </ElCol>
    <ElCol
      v-if="isVideoOrDoc"
      :span="2"
      class="file-table__header-col"
    >
      <div class="file-table__header-title file-table__header-title_align-right">
        {{ t('labels.type') }}
      </div>
    </ElCol>
    <ElCol
      :span="13"
      class="file-table__header-col file-table__header-col_align-left"
    >
      <div class="file-table__header-title">
        {{ t('fields.url') }}
      </div>
    </ElCol>
    <ElCol
      v-if="isLink"
      :span="3"
      class="file-table__header-col file-table__header-col_align-left"
    >
      <div class="file-table__header-title">
        {{ t('labels.access') }}
      </div>
    </ElCol>
    <ElCol
      :span="isVideoOrDoc ? 8 : 7"
      class="file-table__header-col"
    >
      <div class="file-table__header-title file-table__header-title_align-center">
        {{ t('fields.caption') }}
      </div>
    </ElCol>
  </ElRow>
  <draggable
    :list="list"
    v-bind="dragOptions"
    itemKey="hash"
    @start="drag = true"
    @end="handleDragEnd"
  >
    <template #item="{ element, index }">
      <div :key="`instruction-${element.hash}`">
        <template
          v-for="(localeMedia, indexLang) in element.locales"
          :key="`row-${indexLang}`"
        >
          <template v-if="localeMedia.locale === tabLang">
            <GeneralTableRow
              :class="`local-${localeMedia.locale}`"
              :index="index"
              :indexLang="indexLang"
              :item="element"
              :localeMedia="localeMedia"
              :drag="drag"
              :type="type"
              :isHideSelect="isHideSelect"
              @changeFile="changeFile"
              @inputFileLink="handleLinkInput"
              @inputTextButton="handleTextButtonInput"
              @changeFlag="changeFlag"
              @switchRoles="switchRoles"
              @delete="handleDelete"
            />
          </template>
        </template>
      </div>
    </template>
  </draggable>
</template>
<script setup lang="ts">

import { ElCol, ElRow } from 'element-plus';
import { ref, toRef } from 'vue';
import draggable from 'vuedraggable';
import { useI18n } from 'vue-i18n';
import GeneralTableRow from '@/components/Form/GeneralTableRow.vue';
import { DocumentType, MediaFile, Lang, DocumentTypeEnum, LinkRole } from '@/types/manuals';
import { UploadedPayloadType } from '@/utils/fileUploader';
import { reOrder } from '@/router/Admin/builderManuals';

const { t } = useI18n();

const dragOptions = {
  animation: 150,
  disabled: false,
  ghostClass: 'ghost',
  handle: '.handle-dnd',
};

const props = defineProps<{
  tabLang: Lang,
  list: MediaFile[],
  type: DocumentType,
  isHideSelect?: boolean,
}>();

const emit = defineEmits<{
  (event: 'dragFile', value: MediaFile[]): void;
  (event: 'inputFileLink', value: { index: number, value: string }): void;
  (event: 'changeFile', value: { data: UploadedPayloadType, index: number, indexLang: number }): void;
  (event: 'inputTextButton', value: { index: number, value: string }): void;
  (event: 'delete', value: number): void;
  (event: 'changeFlag', value: { index: number, value: string }): void;
  (event: 'switchRoles', value: { index: number, value: LinkRole[] }): void;
}>();

const list = toRef(props, 'list');
const drag = ref(false);
const isLink = props.type === DocumentTypeEnum.Link;
const isVideoOrDoc = !isLink;

const handleDragEnd = (event: { oldIndex: number, newIndex: number }) => {
  if (event.oldIndex === event.newIndex) {
    return;
  }
  emit('dragFile', reOrder(list.value));
  drag.value = false;
};
const handleLinkInput = (value: { index: number, value: string }) => {
  emit('inputFileLink', value);
};

const changeFile = (value: { data: UploadedPayloadType, index: number, indexLang: number }) => {
  emit('changeFile', value);
};

const handleTextButtonInput = (value: { index: number, value: string }) => {
  emit('inputTextButton', value);
};
const changeFlag = (value: { index: number, value: string }) => {
  emit('changeFlag', value);
};

const switchRoles = (value: { index: number, value: LinkRole[] }) => {
  emit('switchRoles', value);
};

const handleDelete = (value: number) => {
  emit('delete', value);
};
</script>
