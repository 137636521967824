<template>
  <svg v-bind="attrs">
    <circle
      cx="22"
      cy="22"
      r="22"
      fill="transparent"
    />
    <circle
      cx="22"
      cy="15.125"
      r="4.5"
      fill="transparent"
      stroke="currentColor"
      stroke-width="2"
    />
    <path
      d="M13.375 31.4286C13.375 26.7736 17.1486 23 21.8036 23H22.1964C26.8514 23 30.625 26.7736 30.625 31.4286C30.625 31.7442 30.3692 32 30.0536 32H13.9464C13.6308 32 13.375 31.7442 13.375 31.4286Z"
      fill="transparent"
      stroke="currentColor"
      stroke-width="2"
    />
  </svg>
</template>

<script lang="ts" setup>
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 44, height: 44 });
</script>
