<template>
  <Modal
    v-if="isOpen"
    class="labs-modal"
    width="330"
    hideCloseButton
    @close="handleClose"
  >
    <template #header>
      <h2 class="labs-modal__title">
        {{ t("labs.oops") }}
      </h2>
    </template>
    <div class="labs-modal__content">
      <p>
        {{ t("labs.mobileModalText") }}
      </p>
      <div class="labs-modal__bottom">
        <ElButton
          type="primary"
          class="labs-modal__btn ui-btn --big-round"
          @click="handleClose"
        >
          {{ t('labs.clearly') }}
        </ElButton>
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import { ElButton } from 'element-plus';
import Modal from '@/components/Modal.vue';

defineProps<{
  isOpen: boolean,
}>();

const emits = defineEmits<{
  (event: 'close'): void,
}>();

const { t } = useI18n();

const handleClose = () => {
  emits('close');
};
</script>

<style lang="scss">
.labs-modal {

  &__title {
    font-size: 24px;
    font-weight: normal;
  }

  &__text {
    margin-bottom: 20px;
  }
  &__bottom {
    display: flex;
    justify-content: end;
    margin-top: 20px;
  }

}
</style>
