import { onBeforeUnmount } from 'vue';
import {
  PublicationContext,
  SubscribedContext,
  SubscriptionTokenContext,
  UnsubscribedContext,
} from 'centrifuge';
import { Subscription } from 'centrifuge/build/subscription';

import { wsMessageLog } from '@/utils';

import { ws, getAuthTokenToChannel } from '@/services/websocket';

type UseWSChannelSubscribe = Subscription | null | undefined;

export const useWSChannelSubscribe = (channel: string): UseWSChannelSubscribe => {
  let wsSubscribe = ws.value?.getSubscription(channel);

  if (!wsSubscribe) {
    ws.value?.newSubscription(channel, {
      getToken: (ctx: SubscriptionTokenContext): Promise<string> => getAuthTokenToChannel(ctx),
    });
    wsSubscribe = ws.value?.getSubscription(channel);
  }

  const init = (): void => {
    if (wsSubscribe) {
      wsSubscribe.subscribe();

      // For Debugging
      if (process.env.NODE_ENV !== 'production') {
        wsSubscribe?.on('subscribed', (ctx: SubscribedContext) => {
          wsMessageLog(ctx, `Channel '${channel}' subscribed`);
        });
        wsSubscribe?.on('publication', (ctx: PublicationContext) => {
          wsMessageLog(ctx, `Channel '${channel}' publication: `);
        });
        wsSubscribe.on('unsubscribed', (ctx: UnsubscribedContext) => {
          wsMessageLog(ctx, `Channel '${channel}' unsubscribed: `);
        });
      }

      onBeforeUnmount(() => {
        wsSubscribe?.unsubscribe();
        wsSubscribe?.removeAllListeners();
        ws.value?.removeSubscription(wsSubscribe || null);
      });
    }
  };

  init();

  return wsSubscribe;
};

export default useWSChannelSubscribe;
