<template>
  <teleport to="#modal">
    <Modal
      v-if="isOpen"
      width="500"
      @close="handleClose"
    >
      <template #header>
        <h2>
          {{ t("footer.systemRequirements") }}
        </h2>
      </template>
      <div v-html="sysReq" />
    </Modal>
  </teleport>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { useStore } from 'vuex';
import Modal from '@/components/Modal.vue';
import { Lang, SysReqResponseBackend } from '@/types/manuals';

const store = useStore();

store.dispatch('Common/getSystemRequirements');
const currentUserLocale = computed<Lang>(() => store.getters.lang);
const sysReq = computed(() => {
  const sysReqList = store.getters['Common/systemRequirements'];
  const data = sysReqList
    .find((item: SysReqResponseBackend) => item.locale === currentUserLocale.value);
  return data?.text || '';
});

const { t } = useI18n();
const props = defineProps<{
  isOpen: boolean;
}>();

const emit = defineEmits<{
  (e: 'close', val: boolean): void;
}>();

const handleClose = () => emit('close', !props.isOpen);
</script>
