<template>
  <ElDialog
    :title="t('commonSysReq')"
    class="general-dialog"
    :closeOnClickModal="false"
  >
    <ElForm
      ref="formEl"
      :model="formData"
      :rules="rules"
      statusIcon
      :validateOnRuleChange="false"
    >
      <ElTabs
        v-model="activeTab"
        type="border-card"
        class="add-page__tabs add-page__gap"
      >
        <ElTabPane
          v-for="lang in tabLocales"
          :key="`tab-${lang.value}`"
          :name="lang.value"
        >
          <template #label>
            {{ localesNamesMapper[lang.value] }}
            <ElIcon class="el-icon--right">
              <div
                v-if="lang.isRequired && lang.isFilled"
                class="--filled"
              >
                <IconSelect />
              </div>
              <div
                v-else-if="lang.isRequired"
                class="--warning"
              />
              <LangIcon :lang="lang.value" />
            </ElIcon>
          </template>

          <ElFormItem
            label=""
            :prop="`${lang.value}_sysReq`"
          >
            <TextEditor
              :value="value"
              :maxlength="1024"
              showCounter
              @blur="updateData"
            />
          </ElFormItem>
        </ElTabPane>
      </ElTabs>

      <ElRow>
        <ElCol class="general-dialog__between">
          <ElButton
            class="ui-btn --big-round"
            @click="undoChanges"
          >
            {{ t('cancel') }}
          </ElButton>
          <ElButton
            type="primary"
            class="ui-btn --big-round"
            :icon="SuccessFilled"
            @click="checkValid"
          >
            {{ t('save') }}
          </ElButton>
        </ElCol>
      </ElRow>
    </ElForm>
  </ElDialog>
</template>

<script setup lang="ts">
import { computed, reactive, watch, ref } from 'vue';
import { SuccessFilled, Select as IconSelect } from '@element-plus/icons-vue';
import { ElDialog, ElIcon, ElTabs, ElTabPane, ElForm, ElFormItem, ElCol, ElRow, ElButton } from 'element-plus';
import cloneDeep from 'lodash.clonedeep';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { Lang, SysReqResponseBackend } from '@/types/manuals';
import LangIcon from '@/components/Form/LangIcon.vue';
import { localesNamesMapper } from '@/constants/languages';
import TextEditor from '@/components/Form/TextEditor/Editor.vue';
import { showValidationError } from '@/utils';

const { t } = useI18n();
const store = useStore();

const emit = defineEmits<{
  (event: 'close'): void;
}>();

const activeTab = ref<Lang>('ru');
const activeField = computed(() => `${activeTab.value}_sysReq`);
const requiredLocales = computed(() => store.getters['Locales/requiredLocales']);
const locales = cloneDeep(store.getters['Locales/locales']);
const formEl = ref();

store.dispatch('Common/getSystemRequirements');
const sysReqArr = computed(() => store.getters['Common/systemRequirements']);
const cloneSysReqArr = computed(() => cloneDeep(sysReqArr.value));
const value = computed(() => formData[activeField.value]);

const formData = reactive<any>({});

watch(cloneSysReqArr, (value) => {
  const data: any = {};
  value.forEach((data: SysReqResponseBackend) => {
    formData[`${data.locale}_sysReq`] = data.text;
  });
  return data;
});

const tabLocales = computed(() => locales.map((loc: any) => {
  loc.isFilled = (() => {
    const isFilled: boolean[] = [];
    const hasText = Boolean(formData[`${loc.value}_sysReq`]);
    isFilled.push(hasText);
    const hasEmptyField = Boolean(isFilled.filter((field) => !field).length);
    return !hasEmptyField;
  })();
  return loc;
}));

const updateData = (value: string) => {
  formData[activeField.value] = value;
};

const rules = computed(() => {
  const data: any = {};
  requiredLocales.value.forEach((lang: Lang) => {
    data[`${lang}_sysReq`] = [{ required: true, message: '', trigger: 'change' }];
  });
  return data;
});


const undoChanges = () => {
  emit('close');
  store.commit('Common/RESET_SYSTEM_REQ');
};

const checkValid = async () => {
  await formEl.value.validate(async (valid: boolean, fields: any) => {
    if (valid) {
      await saveChanges();
    } else {
      showValidationError({ fields, t, supportedLocales: [], isScrollToError: false });
    }
  });
};

const saveChanges = async () => {
  const list = Object.keys(formData).map((key) => ({
    locale: key.split('_')[0],
    text: formData[key],
  }));
  await store.dispatch('Common/setSystemRequirements', list);
  emit('close');
};

</script>
