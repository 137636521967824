<template>
  <svg v-bind="attrs">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.03125 18.0788C3.03125 19.5795 4.84833 20.499 6.25951 19.7123L18.961 12.6322C20.3049 11.8831 20.3047 10.1138 18.9607 9.36491L6.2592 2.28749C4.84801 1.50116 3.03125 2.42065 3.03125 3.92122V18.0788Z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts" setup>
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 23, height: 22 });
</script>
