<template>
  <header :class="['header', { mobile: isMobileScreen }]">
    <div class="container-flex">
      <a
        :href="companyWebsiteLink"
        class="header__logo-link"
        target="_blank"
        :aria-label="t('vrLabs')"
      >
        <AppLogo />
      </a>
      <TransitionFade>
        <HeaderNav class="header__nav" />
      </TransitionFade>
      <HeaderProfile
        v-if="userData"
        @lockLogin="isDisabledLogBtn = true"
      />
      <CleanButton
        v-if="!userData"
        class="header__login"
        :disabled="isDisabledLogBtn || showLoader"
        @click="handleLogin"
      >
        {{ t("menus.login") }}
      </CleanButton>
      <LangSwitcher v-if="!userData" />
    </div>
  </header>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useScreenSize } from '@/composition/useScreenSize';
import { loginUser as handleLogin } from '@/services/userActions';
import { LoggedUser } from '@/types';
import { companyWebsiteLink } from '@/utils/constTemplates';
import AppLogo from '@/components/AppLogo.vue';
import CleanButton from '@/components/CleanButton.vue';
import TransitionFade from '@/components/TransitionFade.vue';
import HeaderNavMobile from '@/components/Header/Nav/Mobile.vue';
import HeaderNavDesktop from '@/components/Header/Nav/Desktop.vue';
import HeaderProfileMobile from '@/components/Header/Profile/Mobile.vue';
import HeaderProfileDesktop from '@/components/Header/Profile/Desktop.vue';
import LangSwitcherMobile from '@/components/Header/LangSwitcher/Mobile.vue';
import LangSwitcherDesktop from '@/components/Header/LangSwitcher/Desktop.vue';

const store = useStore();
const { t } = useI18n();
const { isMobileScreen } = useScreenSize();

const userData = computed<LoggedUser>(() => store.state.UserData.user);
const isDisabledLogBtn = ref(false);
const showLoader = computed(() => store.getters['Common/showLoader']);

const HeaderNav = computed(
  () => (isMobileScreen.value ? HeaderNavMobile : HeaderNavDesktop)
);
const HeaderProfile = computed(
  () => (isMobileScreen.value ? HeaderProfileMobile : HeaderProfileDesktop)
);
const LangSwitcher = computed(
  () => (isMobileScreen.value ? LangSwitcherMobile : LangSwitcherDesktop)
);
</script>

<style scoped lang="scss">
@use "sass:map";
@import "@/assets/style/include";

.header {
  background-color: $header-background;
  height: $header-height;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  z-index: $header-index;
  transition: background-color 1s;
  box-shadow: 0 4px 10px 0 #1D3F4D26;

   :deep(.profile-dropdown)  {
    width: 100%;
    left: 0;
    position: absolute;
    border-radius: 0 0 16px 16px;
    background: $bgLight1;
  }

  .container-flex {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &.mobile {
    background-color: #F5F8FA;
    border-bottom: 1px solid $color-primary-light;
  }
}

.header__logo-link {
  display: inline-flex;
  font-size: 44px;
}

.header__nav {
  flex-grow: 1;
}

.header__login {
  background-color: var(--color-grey-header);
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  transition: background-color $transition-speed;

  @include hover-focus() {
    background-color: var(--color-acent-lightest);
    outline: 0;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity $transition-speed ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@include media-breakpoint-down("mobile") {
  .header {
    height: $header-height-mobile;
    font-size: $header-font-size-small;
  }
  .header__logo-link {
    font-size: 32px;
  }
}

</style>
