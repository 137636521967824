import cloneDeep from 'lodash.clonedeep';
import services from '@/services/admin';
import { DocumentTypeEnum } from '@/types/manuals';
import {
  addFieldManuals,
  createFieldByManuals,
} from '@/router/Admin/builderManuals';

const SET_MANUALS = 'SET_MANUALS';
const RESET_MANUALS = 'RESET_MANUALS';
const SET_ORIGIN_MANUALS = 'SET_ORIGIN_MANUALS';
const ADD_NEW_MANUALS = 'ADD_NEW_MANUALS';

export const Manuals = {
  namespaced: true,
  state: {
    generalVideoManuals: [],
    generalVideoManualsOrigin: [],
    generalDocManuals: [],
    generalDocManualsOrigin: [],
    isManualsLoading: false,
  },
  getters: {
    getGeneralVideoManuals: (state) => state.generalVideoManuals,
    getGeneralDocManuals: (state) => state.generalDocManuals,
    getIsManualsLoading: (state) => state.isManualsLoading,
  },
  actions: {
    async fetchGeneralManuals({ commit }, isVideo) {
      let list = [];
      try {
        commit('SET_IS_MANUALS_LOADING', true);
        list = await services.getGeneralManuals({
          manual_type: isVideo
            ? DocumentTypeEnum.Video
            : DocumentTypeEnum.Document,
        });
      } catch (error) {
        console.error(error);
      } finally {
        if (list?.length) {
          commit(SET_MANUALS, { list: cloneDeep(list), isVideo });
          commit(SET_ORIGIN_MANUALS, { list: cloneDeep(list), isVideo });
        }
        commit('SET_IS_MANUALS_LOADING', false);
      }
    },
    async saveGeneralManuals({ commit }, { data, isVideo }) {
      try {
        const list = await services.updateGeneralManuals(data);
        if (list) {
          commit(SET_MANUALS, { list: cloneDeep(list), isVideo });
          commit(SET_ORIGIN_MANUALS, { list: cloneDeep(list), isVideo });
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
  mutations: {
    SET_IS_MANUALS_LOADING(state, bool) {
      state.isManualsLoading = bool;
    },
    [SET_MANUALS](state, { list, isVideo }) {
      const type = isVideo ? DocumentTypeEnum.Video : DocumentTypeEnum.Document;
      if (isVideo) {
        state.generalVideoManuals = createFieldByManuals(list, type);
      } else {
        state.generalDocManuals = createFieldByManuals(list, type);
      }
    },
    [SET_ORIGIN_MANUALS](state, { list, isVideo }) {
      const type = isVideo ? DocumentTypeEnum.Video : DocumentTypeEnum.Document;
      if (isVideo) {
        state.generalVideoManualsOrigin = createFieldByManuals(list, type);
      } else {
        state.generalDocManualsOrigin = createFieldByManuals(list, type);
      }
    },
    [RESET_MANUALS](state, isVideo) {
      if (isVideo) {
        state.generalVideoManuals = cloneDeep(state.generalVideoManualsOrigin);
      } else {
        state.generalDocManuals = cloneDeep(state.generalDocManualsOrigin);
      }
    },
    [ADD_NEW_MANUALS](state, { isFile, isVideo }) {
      if (isVideo) {
        state.generalVideoManuals = addFieldManuals(state.generalVideoManuals, isFile, isVideo);
      } else {
        state.generalDocManuals = addFieldManuals(state.generalDocManuals, isFile, isVideo);
      }
    },
  },
};

export default {
  Manuals,
};
