<template>
  <div class="licence-info">
    <div
      v-if="expirationDate"
      class="licence-info-text"
    >
      <div
        v-if="isSoonExpire"
        class="licence-info-alert"
      />
      {{ expirationDate }}
    </div>

    <ElTooltip
      v-if="hasStatData"
      class="box-item"
      effect="light"
      :content="tooltipSpendTime"
      rawContent
      placement="bottom"
    >
      <div
        class="licence-info__calendar"
        :class="{ '--small': expirationDate }"
      >
        <Calendar />
        <span class="licence-info__calendar-text">{{ t('statistics') }}</span>
      </div>
    </ElTooltip>
  </div>
</template>
<script setup lang="ts">
import { ElTooltip } from 'element-plus';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import Calendar from '@/components/icons/CalendarClock.vue';
import dayjs from '@/utils/dayjs';
import { LabData } from '@/types/LabData';

const { t } = useI18n();

const props = defineProps<{
  product: LabData;
}>();

const formatTime = (seconds: number) => {
  if (!seconds) { return ''; }
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  let time = hours ? `${hours} ${t('time.hour')}. ` : '';
  time += minutes ? `${minutes} ${t('time.minute')}.` : '';
  return time;
};

const selfExpiredTime = computed(() => {
  if (!props.product.self_license_expiration_date) { return false; }
  return new Date(props.product.self_license_expiration_date).getTime();
});

const expiredTime = computed(() => {
  if (!props.product.license_expiration_date) { return false; }
  return new Date(props.product.license_expiration_date).getTime();
});

const calcExpiredTime = (date: number) => Math.floor((date - new Date().getTime()) / 1000);

const remainedTimeSeconds = computed(() => {
  if (selfExpiredTime.value) {
    return calcExpiredTime(selfExpiredTime.value);
  }
  if (expiredTime.value) {
    return calcExpiredTime(expiredTime.value);
  }
  return false;
});

const isSoonExpire = computed(() => {
  if (!remainedTimeSeconds.value) { return false; }
  return (remainedTimeSeconds.value / 60 / 60) < 48;
});

const expirationDate = computed(() => {
  if (!remainedTimeSeconds.value) { return false; }
  if (remainedTimeSeconds.value < 0) {
    return t('tooltipLicenseExpired');
  }
  if (isSoonExpire.value) {
    return `${t('tooltipLicense')} ${t('tooltipAfter')} ${formatTime(remainedTimeSeconds.value)}`;
  }
  return `${t('tooltipLicense')} ${dayjs(props.product.license_expiration_date).format('DD.MM.YYYY')}`;
});

const productSpendTime = computed(() => {
  const spendTime = props.product.product_active_duration;
  if (!spendTime) { return '–'; }
  return spendTime < 60 ? t('lessOneMinute') : formatTime(spendTime);
});

const productLastLaunch = computed(() => {
  if (!props.product.product_last_start) { return '–'; }
  return `${dayjs(props.product.product_last_start).format('DD.MM.YYYY HH:mm')}`;
});

const tooltipSpendTime = computed(() => {
  let data = `${t('tooltipSpendTime')}: ${productSpendTime.value}</br>`;
  data += `${t('tooltipLastLaunch')}: ${productLastLaunch.value}`;
  return data;
});

const hasStatData = computed(
  () => props.product.product_last_start || props.product.product_active_duration
);
</script>

<style lang="scss">
@import "@/assets/style/include.scss";

.licence-info {
  height: 36px;
  background: $color-white;
  font-size: 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  overflow: hidden;
  background: linear-gradient(15deg, #FFF 10%, #CFCFCF 90%) padding-box, $borderGradient;
  border: 1px solid transparent;
  border-bottom: none;

  &-text {
    padding: 0 16px;
    display: flex;
    align-items: center;
  }

  &-alert {
    width: 19px;
    height: 18px;
    background: url("@/assets/img/alert.svg");
    margin-right: 4px;
  }

  &__calendar {
    height: 100%;
    background: linear-gradient(15deg, #F3F3F3 10%, #B8B8B8 90%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 8px;

    svg {
      width: 24px;
      height: 24px;
      margin: 0 6px;
    }

    &.--small {
      padding: 0 5px;

      svg {
        margin: 0;
      }

      .licence-info__calendar-text {
        display: none;
      }
    }
  }
}

@include media-breakpoint-down("mobile") {
  .licence-info {
    height: 28px;

    &-text {
      white-space: nowrap;
    }
  }
}
</style>
