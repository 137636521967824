<template>
  <div>
    <template v-for="(item, index) in items">
      <div
        v-if="item.type === 'divider'"
        :key="`divider${index}`"
        class="divider"
      />
      <MenuItem
        v-else
        :key="index"
        :icon="item.icon"
        :title="item.title || ''"
        :action="item.action || function() {}"
        :isActive="item.isActive"
      />
    </template>

    <ModalSetLink ref="$modal">
      <template #header>
        <h3 class="modal-link__title">
          {{ t('btnSetLink') }}
        </h3>
      </template>

      <label class="modal-link__label">
        URL
        <UiInput
          :value="url"
          class="modal-link__input"
          type="url"
          :error="!validateURL && url !== ''"
          @input="handleUpdate"
        />
      </label>

      <template #buttons>
        <ElButton @click="handleClose">
          {{ t('cancel') }}
        </ElButton>

        <ElButton
          type="primary"
          :icon="SuccessFilled"
          @click="handleSave"
        >
          {{ t('save') }}
        </ElButton>
      </template>
    </ModalSetLink>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { Editor } from '@tiptap/vue-3';
import { ElButton } from 'element-plus';
import { useI18n } from 'vue-i18n';
import { SuccessFilled } from '@element-plus/icons-vue';
import { useRegExp } from '@/constants/default';
import UiInput from '../UiInput.vue';
import ModalSetLink from './Addlink.vue';
import Bold from './icons/Bold.vue';
import Italic from './icons/Italic.vue';
import Strikethrough from './icons/Strikethrough.vue';
import CodeView from './icons/CodeView.vue';
import H1 from './icons/H1.vue';
import H2 from './icons/H2.vue';
import IconLink from './icons/Link.vue';
import Paragraph from './icons/Paragraph.vue';
import ListUnordered from './icons/ListUnordered.vue';
import ListOrdered from './icons/ListOrdered.vue';
import CodeBox from './icons/CodeBoxFill.vue';
import DoubleQuotes from './icons/DoubleQuotes.vue';
import Separator from './icons/Separator.vue';
import TextWrap from './icons/TextWrap.vue';
import FormatClear from './icons/FormatClear.vue';
import ArrowGoBackLine from './icons/ArrowGoBackLine.vue';
import ArrowGoForwardLine from './icons/ArrowGoForwardLine.vue';
import MenuItem from './MenuItem.vue';

const { t } = useI18n();

const props = defineProps<{
  editor: Editor
}>();

const $modal = ref<HTMLDialogElement | null>(null);
const url = ref<string>('');

const validateURL = computed(() => useRegExp.correctUrl.test(url.value));

const setLink = () => {
  url.value = props.editor.getAttributes('link').href || '';
  $modal.value?.showModal();
};

const handleClose = () => {
  $modal.value?.close();
};

const handleUpdate = (newUrl: string): void => {
  url.value = newUrl;
};

const handleSave = (): void => {
  if (url.value && !validateURL.value) {
    return;
  }

  const newUrl = url.value;
  url.value = '';
  handleClose();

  // empty
  if (newUrl === '') {
    props.editor
      .chain()
      .focus()
      .extendMarkRange('link')
      .unsetLink()
      .run();

    return;
  }

  // update link
  props.editor
    .chain()
    .focus()
    .extendMarkRange('link')
    .setLink({ href: newUrl, target: '_blank' })
    .run();
};

const items = [
  {
    icon: Bold,
    title: t('btnBold'),
    action: () => props.editor.chain().focus().toggleBold().run(),
    isActive: () => props.editor.isActive('bold'),
  },
  {
    icon: Italic,
    title: t('btnItalic'),
    action: () => props.editor.chain().focus().toggleItalic().run(),
    isActive: () => props.editor.isActive('italic'),
  },
  {
    icon: Strikethrough,
    title: t('btnCross'),
    action: () => props.editor.chain().focus().toggleStrike().run(),
    isActive: () => props.editor.isActive('strike'),
  },
  {
    icon: IconLink,
    title: t('btnAddLink'),
    action: setLink,
    isActive: () => props.editor.isActive('link'),
  },
  {
    icon: CodeView,
    title: t('btnAddCode'),
    action: () => props.editor.chain().focus().toggleCode().run(),
    isActive: () => props.editor.isActive('code'),
  },
  {
    type: 'divider',
  },
  {
    icon: H1,
    title: `${t('btnH')} 1`,
    action: () => props.editor.chain().focus()
      .toggleHeading({ level: 1 }).run(),
    isActive: () => props.editor.isActive('heading', { level: 1 }),
  },
  {
    icon: H2,
    title: `${t('btnH')} 2`,
    action: () => props.editor.chain().focus()
      .toggleHeading({ level: 2 }).run(),
    isActive: () => props.editor.isActive('heading', { level: 2 }),
  },
  {
    icon: Paragraph,
    title: t('btnP'),
    action: () => props.editor.chain().focus().setParagraph().run(),
    isActive: () => props.editor.isActive('paragraph'),
  },
  {
    icon: ListUnordered,
    title: t('btnUnnumberedList'),
    action: () => props.editor.chain().focus().toggleBulletList().run(),
    isActive: () => props.editor.isActive('bulletList'),
  },
  {
    icon: ListOrdered,
    title: t('btnNumberedList'),
    action: () => props.editor.chain().focus().toggleOrderedList().run(),
    isActive: () => props.editor.isActive('orderedList'),
  },
  {
    icon: CodeBox,
    title: t('btnCode'),
    action: () => props.editor.chain().focus().toggleCodeBlock().run(),
    isActive: () => props.editor.isActive('codeBlock'),
  },
  {
    type: 'divider',
  },
  {
    icon: DoubleQuotes,
    title: t('btnQuote'),
    action: () => props.editor.chain().focus().toggleBlockquote().run(),
    isActive: () => props.editor.isActive('blockquote'),
  },
  {
    icon: Separator,
    title: t('btnHorizontalDivider'),
    action: () => props.editor.chain().focus().setHorizontalRule().run(),
  },
  {
    type: 'divider',
  },
  {
    icon: TextWrap,
    title: t('btnNewLine'),
    action: () => props.editor.chain().focus().setHardBreak().run(),
  },
  {
    icon: FormatClear,
    title: t('btnClear'),
    action: () => props.editor.chain()
      .focus()
      .clearNodes()
      .unsetAllMarks()
      .run(),
  },
  {
    type: 'divider',
  },
  {
    icon: ArrowGoBackLine,
    title: t('btnCancel'),
    action: () => props.editor.chain().focus().undo().run(),
  },
  {
    icon: ArrowGoForwardLine,
    title: t('btnRepeat'),
    action: () => props.editor.chain().focus().redo().run(),
  },
];
</script>

<style lang="scss">
@import "@/assets/style/include.scss";

.divider {
  background-color: $color-gray;
  height: 1.25rem;
  margin-left: 0.5rem;
  margin-right: 0.75rem;
  width: 1px;
}
</style>
