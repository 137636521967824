<template>
  <div
    v-if="recent && recent.length > 0"
    class="recent"
  >
    <h2 class="block__title">
      {{ t("labs.recent") }}
    </h2>

    <CarouselCards
      class="recent__carousel"
      :list="recent"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { useStore } from 'vuex';
import CarouselCards from '@/components/Carousel/CarouselCards.vue';
import { LabData } from '@/types/LabData';

const store = useStore();

const props = defineProps<{
  products: LabData[];
}>();

const recentId = computed(() => store.getters['Common/recent']);
const recent = computed(
  () => {
    const list: LabData[] = [];
    recentId.value.forEach((id: string) => {
      const item = props.products.find((item) => String(item.id) === String(id));
      if (item) {
        list.push(item);
      }
    });
    return list;
  }
);

const { t } = useI18n();
</script>

<style scoped lang="scss">
@import "@/assets/style/include";

.recent {
  margin-bottom: 18px;

  .block__title {
    margin: 16px 0;
  }

  &__carousel {
    padding: 0 0 10px;
    border-radius: 12px;
  }
}

@include media-breakpoint-down("mobile") {
  .recent {

    &__carousel {
      padding: 0;
      background: $bgLight1;
    }
  }
}
</style>
