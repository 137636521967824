<template>
  <div class="search-switch__box">
    <div
      class="search-switch"
      :class="{ 'active': isActive, 'error': isNotFound }"
    >
      <div
        class="search-switch__find"
        @click="switchMobileInput"
      >
        <IconMagnifier />
      </div>
      <CleanInput
        ref="input"
        :value="searchRequest"
        :placeholder="placeholder"
        class="search-switch__input"
        @input="useInputEvent ? handleInput($event) : undefined"
        @keyup.enter="handleSearch"
        @focus="isActive = true"
        @blur="blurSearchInput"
      />
      <div
        v-if="searchRequest"
        class="search-switch__clean"
        @click="cleanSearchInput"
      >
        <Delete />
      </div>
    </div>
    <div class="search-switch__line" />
  </div>
</template>

<script lang="ts" setup>
import { nextTick, ref } from 'vue';
import CleanInput from '@/components/inputs/CleanInput.vue';
import IconMagnifier from '@/components/icons/Magnifier.vue';
import Delete from '@/components/icons/Delete.vue';

interface Props {
  value?: string;
  placeholder?: string;
  useInputEvent?: boolean;
  isNotFound?: boolean;
}

withDefaults(defineProps<Props>(), {
  value: '',
  placeholder: 'search',
  useInputEvent: false,
  isNotFound: false,
});

const emit = defineEmits<{
  (event: 'search', value: string): void
  (event: 'input', value: string): void
}>();

const input = ref();
const isActive = ref(false);
const searchRequest = ref();

const switchMobileInput = () => {
  isActive.value = true;
  nextTick(() => {
    input.value.$el.focus();
  });
};

const handleInput = (val: string) => {
  searchRequest.value = val;
  emit('input', val);
};

const cleanSearchInput = () => {
  input.value?.$el.focus();
  searchRequest.value = '';
  emit('input', '');
};

const handleSearch = () => {
  const val = input.value?.$el.value;
  emit('search', val);
};

const blurSearchInput = () => {
  if (searchRequest.value) {
    return false;
  }
  isActive.value = false;
};

</script>

<style lang="scss">
@import "@/assets/style/include.scss";

.search-switch {
  width: 56px;
  height: 30px;
  max-width: 360px;
  position: relative;
  color: $color-primary;
  border: 1px solid var(--color-acent);
  outline: 10px solid $bgLight1;
  background: $color-white;
  border-radius: 22px;
  display: flex;
  margin: 0 auto;
  z-index: 1;
  transition: $transition-speed width;
  overflow: hidden;

  &:hover, &.active {
    width: 50%;
  }

  &:hover {
    background: $bgLight3;
  }

  &.active {
    background: white;
  }

  &.error {
    border-color: $input-border-color-error;
    background: white;

    .search-switch__find,
    .search-switch__clean,
    .search-switch__input {
      color: $input-border-color-error;

      &::placeholder {
        color: $input-border-color-error;
      }
    }
  }

  &__box {
    width: 100%;
    position: relative;
  }

  &__line {
    width: 100%;
    height: 1px;
    background: $colorBlue1;
    position: absolute;
    left: 0;
    top: 50%;
  }

  &__find {
    margin-left: 16px;
    color: var(--color-acent);

    svg {
      width: 24px;
      height: $input-height;
    }
  }

  &__input {
    width: calc(100% - 74px);
    padding: 0.5rem 1rem 0.5rem 1rem;
    text-overflow: ellipsis;

    &::placeholder {
      color: var(--color-acent);
      font-style: italic;
    }
  }

  &__button {
    align-items: center;
    color: $color-primary;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    width: 3rem;
  }

  &__clean {
    margin-right: 10px;
    cursor: pointer;
    color: $colorBlue1;

    svg {
      width: 24px;
      height: $input-height;
    }
  }
}

@include media-breakpoint-down("tablet") {
  .search-switch {
    &:hover, &.active {
      width: 60%;
    }
  }
}

@include media-breakpoint-down("mobile") {
  .search-switch {
    width: 80%;
    max-width: 80%;

    &:hover, &.active {
      width: 80%;
      max-width: 80%;
    }
  }
}
</style>
