import { RouteRecordRaw } from 'vue-router';
import { names, paths, permissions } from '@/constants/routes';

const editProduct: Array<RouteRecordRaw> = [
  {
    name: names.editProduct,
    path: paths.editProduct,
    meta: {
      permissions: permissions.addEditProduct,
      isAuthed: false,
      requiresAuth: true,
    },
    component: () => import('./index.vue'),
  },
];

export default editProduct;
