<template>
  <div
    ref="$dropdown"
    class="dropdown"
  >
    <slot name="trigger">
      <span>Dropdown</span>
    </slot>
    <transition
      name="dropdown"
      appear
      v-on="transitionListeners"
    >
      <div
        v-show="show"
        ref="$content"
        v-bind="$attrs"
        :key="key"
        :class="[`dropdown__content`, {
          [`dropdown_fullwidth`]: props.fullwidth,
          [`dropdown_top`]: moveToTop,
        }]"
        :style="style"
        v-on="elementListeners"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import { computed, getCurrentInstance, ref, watch } from 'vue';
import { hasSpaceAtBottom } from '@/utils/dom';
import { useWindowResize } from '@/libraries/useWindowResize';

const emit = defineEmits([
  'afterEnter',
  'afterLeave',
  'beforeEnter',
  'beforeLeave',
  'enter',
  'enterCancelled',
  'leave',
  'leaveCancelled',

  'click',
  'mouseout',
  'mousedown',
  'mouseup',
]);

interface Props {
  fullwidth?: boolean;
  show: boolean;
  top?: boolean;
  right?: boolean;
  indent?: number;
}

const props = withDefaults(defineProps<Props>(), {
  fullwidth: false,
  top: false,
  right: false,
  indent: 5,
});

defineOptions({ inheritAttrs: false });

const $dropdown = ref<HTMLDivElement | null>(null);
const $content = ref<HTMLDivElement | null>(null);

const moveToTop = ref(false);
const controlRect = ref<DOMRect | undefined>(undefined);

const style = computed(() => {
  const contentRect = $content.value?.getBoundingClientRect();

  if (!controlRect.value || !contentRect) {
    return {};
  }

  const toTopPixels = moveToTop.value
    ? contentRect.height + controlRect.value.height + props.indent
    : -props.indent;

  return {
    width: props.fullwidth ? `${controlRect.value.width}px` : undefined,
    top: `${(controlRect.value.bottom - toTopPixels)}px`,
    left: !props.right ? `${controlRect.value.x}px` : undefined,
    right: props.right ? `${window.innerWidth - controlRect.value.right}px` : undefined,
  };
});

const currentInstance = getCurrentInstance();
const key = currentInstance?.vnode.key;

const transitionListeners = {
  'after-enter': (el: HTMLElement) => emit('afterEnter', el),
  'after-leave': (el: HTMLElement) => {
    emit('afterLeave', el);
    moveToTop.value = false;
  },
  'before-enter': (el: HTMLElement) => {
    emit('beforeEnter', el);

    setTimeout(() => {
      moveToTop.value = props.top || !hasSpaceAtBottom(el);
    }, 0);
  },
  'before-leave': (el: HTMLElement) => emit('beforeLeave', el),
  enter: (el: HTMLElement) => emit('enter', el),
  'enter-cancelled': (el: HTMLElement) => emit('enterCancelled', el),
  leave: (el: HTMLElement) => emit('leave', el),
  'leave-cancelled': (el: HTMLElement) => emit('leaveCancelled', el),
};

const elementListeners = {
  click: (e: Event) => emit('click', e),
};

const updateControlRect = (): void => {
  controlRect.value = $dropdown.value?.getBoundingClientRect?.();
};

watch(
  () => props.show,
  () => updateControlRect(),
);

useWindowResize(updateControlRect, 100);
</script>

<style lang="scss">
@use "sass:math";
@import "@/assets/style/variables.scss";

.dropdown {
  position: relative;
}

.dropdown__content {
  background-color: white;
  border: black;
  box-shadow: 0 5px 5px rgba(black, 0.2);
  box-sizing: border-box;
  overflow-y: auto;
  padding: math.div($base-indent, 2) math.div($base-indent, 4);
  position: fixed;
  transition: opacity 0.05s ease, transform 0.1s ease;
  user-select: none;
  z-index: 1200;

  &_fullwidth {
    width: 100%;
  }

  &-enter-active,
  &-leave-to {
    opacity: 0;
    transform: translateY(-10%);
  }
}
</style>
