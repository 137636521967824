<template>
  <ElInput
    v-model="modelValue"
    :maxlength="maxlength"
    :disabled="disabled"
    :showWordLimit="showCounter"
    :type="type"
    :accept="accept"
    :placeholder="placeholder"
    :autosize="autosize"
    :class="classInput"
    class="main-input"
    @input="$emit('input', $event)"
    @keydown="$emit('keydown', $event)"
    @focus="$emit('focus', $event)"
    @blur="$emit('blur', $event)"
  >
    <template
      v-if="copy"
      #append
    >
      <InputAction
        class="main-input__copy_icon"
        :disabled="!value"
        @click="copyToClipboard"
      >
        <IconCopy />
      </InputAction>
    </template>
  </ElInput>
</template>

<script setup lang="ts">
import { toRef, computed } from 'vue';
import { ElInput } from 'element-plus';
import IconCopy from '@/components/icons/Copy.vue';
import InputAction from './InputAction.vue';

interface Props {
  type?: string,
  value: string,
  maxlength?: number,
  disabled?: boolean,
  error?: any,
  showCounter?: boolean,
  placeholder?: string,
  copy?: boolean,
  accept?: string,
  autosize?: boolean | {minRows: number, maxRows: number},
}

const props = withDefaults(defineProps<Props>(), {
  value: '',
  type: 'text',
  disabled: false,
  maxlength: Infinity,
  showCounter: false,
  error: null,
  placeholder: '',
  copy: false,
  accept: '',
  autosize: false,
});

defineEmits<{
  (event: 'input', value: string): void;
  (event: 'keydown', payload: any): void;
  (event: 'focus', payload: FocusEvent): void;
  (event: 'blur', payload: FocusEvent): void;
}>();

const modelValue = toRef(props, 'value');

const classInput = computed(() => ({
  'is-counter-max': props.maxlength === modelValue.value.length,
  'is-error': props.error,
  'is-copy': props.copy,
}
));

const copyToClipboard = () => {
  navigator.clipboard.writeText(props.value);
};

</script>

<style lang="scss">
@import "@/assets/style/include.scss";

.main-input {

  &.el-textarea {
    border: $input-border;
    border-radius: 2px;
    padding-bottom: 24px;
    transition: $transition-speed border;

    &:hover {
      border-color: $colorBlue1;
    }

    &.is-disabled {
      background-color: $color-disabled;
      border: none;

      .el-textarea {

        &__inner {
          background-color: $color-disabled;
          cursor: auto;
          box-shadow: none;
        }
      }
    }

    &.is-exceed {
      border-color: $input-border-color-error;
    }

    &.is-exceed .el-textarea__inner {
      box-shadow: none;
    }
  }

  .el-textarea {

    &__inner {
      resize: none;
      box-shadow: none;
    }
  }

  &.el-input {
    height: $input-height;
  }

  &.is-disabled {

    .el-input {

      &__wrapper {
        background-color: $color-disabled;
        box-shadow: none;

        &:hover {
          box-shadow: 0 0 0 1px $colorGrayDisabled inset;
        }
      }

      &__inner {
        font-size: $input-font-size;
        -webkit-text-fill-color: $input-text-color-disabled;
        color: $input-text-color-disabled;
        cursor: auto;
      }
    }
  }

  .el-input {

    &__wrapper {
      border-radius: $form-border-radius;
    }

    &__inner {
      text-overflow: ellipsis;
      line-height: 1.2;

      &::placeholder {
        color: $input-placeholder-color;
        font-style: italic;
        font-weight: normal;
        opacity: 1;
      }
    }

    &__count {
      color: #cccccc;
      font-weight: 700;
      font-size: 12px;
    }
  }

  &.is-counter-max {
    .el-input {
      &__count {
        color: $color-black-lighter;
      }
    }
  }

  &.is-error {
    .el-input {
      &__wrapper {
        box-shadow: 0 0 0 1px $input-border-color-error inset;
      }
    }

    .el-textarea {
      border-color: $input-border-color-error;
    }
  }

  &.is-copy {
    .el-input {
      &-group__append {
        padding: 0;
        border-bottom-right-radius: 12px;
        border-top-right-radius: 12px;
        background: none;
        box-shadow: none;
      }
    }
  }
}

.button-text-input {
  & .el-input__inner {
    text-align: center;
  }
}
</style>
