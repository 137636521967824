<template>
  <div class="container-flex labs">
    <LabsRecent :products="products" />

    <LabsFilter
      :filterSections="filterSections"
      :isNotFound="isEmpty"
      :hasLicense="hasLicense"
      :countLicense="countLicense"
      @update:license="hasLicense = $event"
      @update:group="updateGroup"
      @update:search="updateSearchInput"
    />

    <div
      v-if="isEmpty && searchFilter"
      class="labs__empty"
    >
      {{ t("labs.emptySearch") }}
    </div>
    <TransitionFadeGroup tag="div">
      <LabsCategory
        v-for="[label, categories] in filteredProductsArray"
        v-show="isFiltersNotActive || isActiveFilter(label)"
        :key="label"
        :categories="categories"
      >
        {{ categoryName(label) }}
      </LabsCategory>
    </TransitionFadeGroup>

    <SideMenu v-if="isSuAccessRole" />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import TransitionFadeGroup from '@/components/TransitionFadeGroup.vue';
import { catchErrorShow } from '@/utils/errors';
import LabsRecent from '@/components/Carousel/Recent.vue';
import { LabData, FilterButton } from '@/types/LabData';
import useWSChannelSubscribe from '@/composition/useWebsocket';
import { ProductStateEnum } from '@/types/enums';
import { launcherChannel } from '@/services/websocket';
import LabsFilter from './components/Filter.vue';
import LabsCategory from './components/Category.vue';
import services from './services';
import SideMenu from './components/SideMenu.vue';

const { t } = useI18n();
const store = useStore();

const isSuAccessRole = computed(() => store.getters.isSuAccessRole);
const isUser = computed<boolean>(() => store.getters.isAuthed);
const products = ref<LabData[]>([]);

const searchFilter = ref('');
const filterSections = ref<Map<string, FilterButton>>(new Map());
const hasLicense = ref(false);
const countLicense = computed(() => {
  let count = 0;
  filteredProducts.value.forEach((arr, key) => {
    if (!isFiltersNotActive.value && !isActiveFilter(key)) { return; }
    count += arr.filter((item: LabData) => item.license_state).length;
  });
  return count;
});

const isFiltersNotActive = computed<boolean>(
  () => !Array.from(filterSections.value.values()).some(
    (filter: FilterButton) => filter.active
  )
);

const categoryName = (label: string) => (label ? t(`labs.section.${label}`) : 'No name category');

const filteredProducts = computed<Map<string, LabData[]>>(
  () => products.value.reduce(
    (acc, cur) => {
      if (searchFilter.value && !cur.nameLower.includes(searchFilter.value)) {
        return acc;
      }
      if (hasLicense.value && !cur.license_state) {
        return acc;
      }
      if (!acc.has(cur.section.shortname)) {
        acc.set(cur.section.shortname, [cur]);
      } else {
        acc.get(cur.section.shortname).push(cur);
      }
      return acc;
    },
    new Map()
  )
);

const filteredProductsArray = computed(
  () => Array.from(filteredProducts.value)
);

const isEmpty = computed(() => filteredProducts.value.size === 0);

const createFilterBtn = () => {
  filterSections.value = products.value.reduce((acc, cur: LabData) => {
    if (!acc.has(cur.section.shortname)) {
      acc.set(cur.section.shortname, {
        ...cur.section,
        active: false,
        count: computed(() => filteredProducts.value.get(cur.section.shortname)?.length || 0),
      });
    }
    return acc;
  }, new Map());
};

const getProducts = async () => {
  store.commit('Common/SET_SHOW_LOADER', true);
  try {
    const data = isUser.value
      ? await services.getLaboratoriesList()
      : await services.getPublicLaboratoriesList();
    products.value = data.reduce((acc: LabData[], product: LabData) => {
      acc.push({ ...product, nameLower: product.name.toLowerCase() });
      return acc;
    }, []);

    if (products.value.length > 0) {
      setLastPublishedProduct();
      createFilterBtn();
    }
  } catch (err) {
    catchErrorShow(err, t);
  } finally {
    store.commit('Common/SET_SHOW_LOADER', false);
  }
};

const updateGroup = (key: string) => {
  if (filterSections.value?.get(key)) {
    const elem: FilterButton = filterSections.value?.get(key);
    elem.active = !elem.active;
  }
};

const updateSearchInput = (searchValue: string) => {
  searchFilter.value = searchValue.trim().toLowerCase();
};

const isActiveFilter = (label: string) => filterSections.value.get(label)?.active;

const lastPublishedProduct = ref();
const subscribeToWsChannel = () => {
  const wsSubscribe = useWSChannelSubscribe(launcherChannel.value);
  wsSubscribe?.on('publication', ({ data }: { data: any }) => {
    if (data?.event === 'product.publication.end') {
      lastPublishedProduct.value = data.id;
      setLastPublishedProduct();
    }
  });
};

if (isSuAccessRole.value) {
  subscribeToWsChannel();
}

const setLastPublishedProduct = () => {
  if (!lastPublishedProduct.value) { return; }
  const product = products.value
    .find((product: LabData) => product.id === lastPublishedProduct.value);
  if (!product) { return; }
  product.state = ProductStateEnum.Complete;
  product.enabled = true;
};

getProducts();

</script>

<style lang="scss">
@import "@/assets/style/include.scss";

.labs {
  display: flex;
  flex-direction: column;
  padding: 54px $containerPaddingFlex;
  background: $bgLight1;
  position: relative;
}

.labs__empty {
  font-style: italic;
  font-size: 20px;
  color: hsl(223, 35%, 51%);
  margin-top: 60px;
  margin-bottom: 60px;
}

@include media-breakpoint-down("tablet") {
  .labs {
    padding: 54px $containerPaddingFlexTablet;
  }
}
@include media-breakpoint-down("mobile") {
  .labs {
    padding: 20px $containerPaddingFlexMobile;
  }
}
</style>
