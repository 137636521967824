<template>
  <div class="header-profile">
    <ModalMenu
      :isShow="isShow"
      fullWidth
      @close="handleClose"
    >
      <template #button="{ buttonClass }">
        <UserAvatar
          :avatarLink="userData.small_avatar"
          :class="['header-profile__avatar', buttonClass]"
          :isMobile="true"
          @click="handleToggle"
        />
      </template>
      <template #content>
        <ModalMenuItem
          class="header-profile__role"
          disabled
          right
        >
          {{ displayedRole }}
        </ModalMenuItem>

        <ModalMenuItem
          :to="{ name: names.profile }"
          class="header-profile__link"
          right
          @click="handleClose"
        >
          {{ t("menus.profile") }}
          <template #icon="{ iconClass }">
            <UserIcon :class="iconClass" />
          </template>
        </ModalMenuItem>

        <ModalMenuItem
          class="header-profile__link"
          right
          @click="handleLogout"
        >
          {{ t("menus.logout") }}
          <template #icon="{ iconClass }">
            <LogoutIcon :class="iconClass" />
          </template>
        </ModalMenuItem>
      </template>
    </ModalMenu>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { names } from '@/constants/routes';
import { roles } from '@/constants/roles';

import UserAvatar from '@/components/UserAvatar.vue';

import { logoutUser } from '@/services/userActions';
import { LoggedUser } from '@/types/user';

import ModalMenu from '@/components/Header/ModalMenu.vue';
import ModalMenuItem from '@/components/Header/ModalMenuItem.vue';
import LogoutIcon from '@/components/icons/Menu/Logout.vue';
import UserIcon from '@/components/icons/Menu/User.vue';

const store = useStore();

const isShow = ref<boolean>(false);
const { t } = useI18n();

const userData = computed<LoggedUser>(() => store.state.UserData.user);

const displayedRole = computed<string>(() => {
  const roleData = userData.value.role;
  if (roleData.shortname === roles.student && !userData.value.organization) {
    return t('roles.user');
  }
  return t(`roles.${roleData.shortname}`);
});

const handleToggle = (): void => {
  isShow.value = !isShow.value;
};

const handleClose = (): void => {
  isShow.value = false;
};

const handleLogout = async (): Promise<void> => {
  handleClose();
  await logoutUser();
  await store.dispatch('clearUserData');
};

</script>

<style lang="scss" src="./index.scss"></style>

<style scoped lang="scss">
@use "sass:math";
@import "@/assets/style/include";

.header-profile__role {
  background-color: $colorBlue1;
  color: $color-white;
  font-size: 15px;
  padding-top: 11px;
  padding-bottom: 11px;
}
.header-profile__avatar {
  border: none;
}
</style>
