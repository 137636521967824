import cloneDeep from 'lodash.clonedeep';
import {
  Manual,
  CheckBoxFlag,
  DocumentType,
  DocumentTypeEnum,
  MediaFields,
  MediaFile,
  MediaFileEnum,
  UniversalManualsEnum,
  NewProduct, Locale, Lang,
} from '@/types/manuals';
import { generateHash } from '@/utils';

const mediaMock = {
  order: 0,
  hash: '',
  flag: '',
  type: 'link',
  file_type: 'video',
  isDisabled: false,
  for_roles: [],
};

const builder = (list: MediaFile[], fileType: DocumentType) => {
  const manuals: Manual[] = [];

  list.forEach((item: MediaFile) => {
    const locales: any = item.locales?.filter((l) => l.caption && l.file);

    const obj: Manual = {
      id: item.id,
      order: item.order,
      file_type: fileType,
      flag: item.flag,
      locales,
      view_type: item.view_type,
      hash: item.hash,
    };
    // есть только у общих мануалов добавленных в продукт
    if (item.parent_id) {
      obj.parent_id = item.parent_id;
    }

    manuals.push(obj);
  });
  return manuals;
};

const constructManuals = (product: NewProduct, fileType: DocumentType) => {
  let manuals: Manual[] = [];
  const field = fileType === DocumentTypeEnum.Video
    ? MediaFields.VideoInstructions
    : MediaFields.UsersGuides;
  if (!product[field]) {
    return manuals;
  }
  manuals = builder(product[field], fileType);
  return manuals;
};

const createManualsByFields = (product: NewProduct) => [
  ...constructManuals(product, DocumentTypeEnum.Video),
  ...constructManuals(product, DocumentTypeEnum.Document),
];

const createGeneralManualsByVideo = (list: MediaFile[], isVideo: boolean) => {
  const documentType = isVideo ? DocumentTypeEnum.Video : DocumentTypeEnum.Document;
  return {
    manual_type: documentType,
    manuals: builder(list, documentType),
  };
};

let supportedLocales: Lang[] = [];
const setBuilderLocales = (locales: Lang[]) => {
  supportedLocales = locales;
};

const createFieldByManuals = (manuals: Manual[], fileType: DocumentType): MediaFile[] => {
  const field: MediaFile[] = [];
  if (!manuals.length) { return field; }
  manuals.forEach((manual: any) => {
    if (manual.file_type === fileType) {
      field.push(createFieldByData(manual, fileType));
    }
  });
  return field;
};

const createFieldByData = (list: Manual, fileType: DocumentType) => {
  const fileUrl = fileType === DocumentTypeEnum.Link ? 'url' : 'file';
  const firstLocale = list?.locales?.[0];
  const locales: any = [];
  const mediaType = list.locales[0]?.file_type as MediaFileEnum;
  supportedLocales.forEach((l) => {
    const savedLocale = list.locales.find((loc: any) => loc.locale === l);
    locales.push({
      id: savedLocale?.id || null,
      locale: l,
      caption: savedLocale?.caption || '',
      file: savedLocale ? savedLocale[fileUrl] : '',
      file_type: mediaType,
      filledLang: savedLocale?.filledLang,
      enabled: 1,
    });
  });

  const obj: MediaFile = {
    id: list.id || null,
    order: list.order,
    type: firstLocale?.file_type,
    file_type: list.file_type,
    locales: locales || [],
    file_status: firstLocale?.file_status,
    flag: list.flag || '',
    view_type: list?.view_type,
    hash: list.hash || generateHash(5),
  };
  if (list.parent_id) {
    obj.parent_id = list.parent_id;
  }
  if (fileType === DocumentTypeEnum.Link) {
    obj.for_roles = list.for_roles;
  }

  return obj;
};

const addFieldManuals = (list: any, isFile = false, fieldType: DocumentType, props?: any): any => {
  const mediaType = isFile ? MediaFileEnum.File : MediaFileEnum.Link;
  const locales: any = [];
  supportedLocales.forEach((l) => {
    locales.push({
      locale: l,
      caption: props?.caption || '',
      file: props?.file || '',
      file_type: mediaType,
      enabled: 1,
    });
  });

  const data = {
    ...mediaMock,
    order: Number(list.length) + 1,
    hash: generateHash(5),
    locales,
  };
  data.type = mediaType;
  data.file_type = fieldType;
  data.isDisabled = props?.isDisabled || false;

  if (fieldType === DocumentTypeEnum.Link) {
    data.for_roles = [];
  }

  list.push(data);
  return list;
};

const createParentId = (list: MediaFile[]) => list.map(
  (obj: MediaFile) => {
    const innerObj = obj;
    if (innerObj.id) {
      innerObj.parent_id = innerObj.id;
      delete innerObj.id;
    }

    return innerObj;
  }
);

const getDefaultFlags = (t: any): CheckBoxFlag[] => [
  {
    value: UniversalManualsEnum.Tournament,
    label: t('labels.tournament'),
    checked: false,
  }, {
    value: UniversalManualsEnum.Workbook,
    label: t('labels.workbook'),
    checked: false,
  }, {
    value: UniversalManualsEnum.Saving,
    label: t('labels.saving'),
    checked: false,
  },
];

const acceptVideo = '.webm, .mp4';
const acceptDocument = '.pdf';

const reOrder = (list: MediaFile[], isCloneDeep: boolean = false) => {
  let lastOrder = 0;
  const newList = isCloneDeep ? cloneDeep(list) : [...list];
  return newList.map((obj: any) => {
    const innerObj = obj;
    innerObj.order = ++lastOrder;
    return innerObj;
  });
};

const hasOneUploadedFileInLocales = (locales:Locale[]) => locales.find((x:Locale) => x.file);

// очистить пустые поля, без файлов #1096
const cleanEmptyFileFields = (list: any) => list
  .filter((item: any) => item.id // есть id - уже сохраненные
    || item.type === MediaFileEnum.Link // новые добавленные поля
    || (item.type === MediaFileEnum.File && hasOneUploadedFileInLocales(item.locales)));

const getFieldByType = (type: DocumentType) => {
  if (type === DocumentTypeEnum.Video) { return MediaFields.VideoInstructions; }
  if (type === DocumentTypeEnum.Document) { return MediaFields.UsersGuides; }
  if (type === DocumentTypeEnum.Link) { return MediaFields.Links; }
};

export {
  createManualsByFields,
  createFieldByManuals,
  createFieldByData,
  createGeneralManualsByVideo,
  cleanEmptyFileFields,
  addFieldManuals,
  createParentId,
  getFieldByType,
  getDefaultFlags,
  setBuilderLocales,
  reOrder,
  acceptVideo,
  acceptDocument,
};
