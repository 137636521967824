<template>
  <transition
    :name="cssClass"
    appear
    @beforeEnter="beforeEnterHandler"
  >
    <div :class="[cssClass, $attrs.class]">
      <div
        :class="`${cssClass}__overlay`"
        @click.self="handleClose"
      />
      <div
        :class="`${cssClass}__content`"
        :style="{
          width: width ? `${width}px` : null,
          maxWidth: 'calc(100vw - 46px)',
        }"
      >
        <header :class="`${cssClass}__header`">
          <slot name="header" />

          <VButton
            v-if="!hideCloseButton"
            :class="`${cssClass}__close`"
            appearance="control"
            @click="handleClose"
          >
            <IconTimes />
          </VButton>
        </header>

        <main :class="`${cssClass}__main`">
          <slot />
        </main>
        <footer
          v-if="slots.footer"
          :class="`${cssClass}__footer`"
        >
          <slot name="footer" />
        </footer>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { useSlots } from 'vue';

import IconTimes from '@/components/icons/Times.vue';
import VButton from '@/components/VButton';

const cssClass = 'custom-modal';

const props = defineProps({
  enableScroll: {
    type: Boolean,
    default: false,
  },
  hideCloseButton: {
    type: Boolean,
    default: false,
  },
  width: {
    type: [Number, String],
    default: null,
    validator: (width) => Number(width) > 0,
  },
});

const emit = defineEmits(['close', 'beforeEnter']);

defineOptions({ inheritAttrs: false });

const slots = useSlots();

const handleClose = (ev) => {
  if (!props.enableScroll) {
    document.body.style.overflow = '';
  }
  emit('close', ev);
};

const beforeEnterHandler = (el) => {
  emit('beforeEnter', el);

  if (!props.enableScroll) {
    document.body.style.overflow = 'hidden';
  }
};
</script>

<style lang="scss">
@import "@/assets/style/include.scss";

.custom-modal {
  align-items: center;
  box-sizing: border-box;
  display: grid;
  height: 100%;
  justify-content: center;
  left: 0;
  overflow-y: $modal-content-overflow;
  padding: $modal-padding;
  position: fixed;
  top: 0;
  width: 100%;
  transition: all ease-in-out $modal-transition-duration;
  z-index: $modal-z-index;
  line-height: normal;

  @include media-breakpoint-down("sm") {
    padding: $modal-padding-mobile;
  }

  &__content {
    display: flex;
    flex-direction: column;
    background-color: $modal-content-background-color;
    border-radius: $modal-content-border-radius;
    box-shadow: $modal-content-box-shadow;
    margin: $modal-content-margin;
    max-width: calc(100% - 24px);
    padding: $modal-content-padding;
    position: relative;
    transition: all ease-in-out $modal-transition-duration;
    width: 100%;
    z-index: 2;
    max-height: 75vh;
    gap: $modal-content-gap;

    @include media-breakpoint-down("sm") {
      border-radius: $modal-content-border-radius-mobile;
      margin: $modal-content-margin-mobile;
    }
  }

  &__overlay {
    background-color: $modal-overlay-background;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;

    @include media-breakpoint-down("sm") {
      background-color: $modal-overlay-background-mobile;
    }
  }

  &__header {
    padding-right: $modal-header-padding-right;
  }

  &__main {
    flex-shrink: 1;
    overflow-y: auto;
    padding-left: 2px;
    padding-right: 2px;
  }

  &__close {
    color: black;
    font-size: $modal-close-font-size;
    padding: $modal-close-padding;
    position: absolute;
    right: $modal-close-right;
    top: $modal-close-top;
  }

  &-enter-active,
  &-leave-active {
    opacity: 0;

    & > .modal__content {
      transform: scale(1.1);
    }
  }
}
</style>
