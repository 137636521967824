import { allowedKeysForInputs } from '@/constants/keys';
import { useRegExp } from '@/constants/default';

// eslint-disable-next-line import/prefer-default-export
export const handleKeyDownNumber = (
  event: KeyboardEvent,
  regExp?: RegExp,
  isCorrect?: boolean,
  optionalRegExp?: RegExp,
): void => {
  const { key, keyCode } = event;
  const activeKeys = allowedKeysForInputs;
  const {
    value,
    selectionStart,
    selectionEnd,
  } = (event.target as HTMLInputElement);
  if (activeKeys.includes(keyCode)) {
    return;
  }
  // eslint-disable-next-line max-len
  const currentString = `${value.slice(0, selectionStart ?? 0)}${key}${value.slice(selectionEnd ?? value.length)}`;
  if (!regExp?.test(key)
      || optionalRegExp?.test(`${value}${key}`)
      || (isCorrect && !useRegExp.correctNumber.test(currentString))
  ) {
    return event.preventDefault();
  }
};


export const hasSpaceAtBottom = (
  el: HTMLElement
): boolean => {
  if (!window || !document || !document.body) {
    return true;
  }

  const elRect = el.getBoundingClientRect();

  if (elRect.top + window.scrollY > elRect.height) {
    const bodyHeight = Math.max(document.body.clientHeight, window.innerHeight);

    if (elRect.bottom + window.scrollY > bodyHeight) {
      return false;
    }
  }

  return true;
};

export const scrollTo = (
  target: HTMLElement,
  direction: ScrollLogicalPosition = 'start',
  behavior: ScrollBehavior = 'smooth'
): void => {
  target.scrollIntoView({
    behavior,
    block: direction,
  });
};

export const scrollToTop = (): void => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export default {
  hasSpaceAtBottom,
  scrollTo,
  scrollToTop,
  handleKeyDownNumber,
};
