<template>
  <div class="video-card">
    <a
      v-if="isLink(card)"
      :href="file"
      target="_blank"
    >
      <div class="video-card__img-box">
        <img
          src="/img/empty/placeholderBg.webp"
          :alt="text"
          class="video-card__img"
        />
      </div>
      <div class="video-card__video-title">
        {{ text }}
      </div>
    </a>
    <VideoPlayer
      v-else
      :path="file"
      :title="text"
      :autoplay="true"
      width="70%"
      openButtonCssClass="video-card__btn"
      :openButtonText="text"
    >
      <template #video-preview>
        <div class="video-card__img-box">
          <video
            :src="file"
            preload="metadata"
            class="video-card__img"
          />
        </div>
      </template>
      <template #btn-text>
        <div class="video-card__video-title">
          {{ text }}
        </div>
      </template>
    </VideoPlayer>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from 'vuex';
import VideoPlayer from '@/components/VideoPlayer/index.vue';
import { ManualViewTypeEnum, MediaFile } from '@/types/manuals';

const store = useStore();

const props = defineProps<{
  card: MediaFile;
}>();

const isLink = (item: MediaFile) => item.view_type === ManualViewTypeEnum.open;
const currentLocaleIndex = computed(() => store.getters['Locales/currentLocaleIndex']);

const file = computed(() => props.card.locales[currentLocaleIndex.value].file);
const text = computed(() => props.card.locales[currentLocaleIndex.value].caption);

</script>

<style scoped lang="scss">
@import "@/assets/style/include";

.video-card {
  display: block;
  position: relative;
  width: 100%;
  transition: border-color $transition-speed;
  padding: 0 8px;
  font-size: 0;

  &__btn {
    padding: 0;
  }

  &__video-cover {
    width: 100%;
    height: 136px;
    object-fit: cover;
    border-top-left-radius: $input-border-radius;
    border-top-right-radius: $input-border-radius;
  }

  &__video-title {
    padding: 8px 10px;
    font-family: "Noto Sans", sans-serif;
    font-size: 12px;
    text-align: center;
    color: hsl(0, 0%, 16%);
    background: #fafafa;
    border-radius: 0 0 14px 14px;
    border: 1px solid $color-disabled;
    border-top: none;
  }

  &__img-box {
    position: relative;
    cursor: pointer;

    &:before {
      width: 100%;
      height: 100%;
      position: absolute;
      content: '';
      display: block;
      background: url("@/assets/svg/play.svg") no-repeat calc(50% + 5px) center;
      opacity: 0;
      transition: opacity $transition-speed;
    }
    &:after {
      width: 88px;
      height: 88px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: '';
      display: block;
      background: white;
      border-radius: 50%;
      opacity: 0;
      transition: opacity $transition-speed;
    }
  }

  &__img {
    border-radius: 14px 14px 0 0;
    width: 100%;
    object-fit: cover;
    border: 1px solid $color-disabled;
    border-bottom: none;
    transition: border $transition-speed;
  }

  &:hover {
    .video-card__img-box:before {
      opacity: 1;
    }
  }
  &:active {
    .video-card__img-box:after {
      opacity: .5;
    }
  }
}

.carousel.is-dragging {
  .video-card{
    &:active .video-card__img-box:after {
      opacity: 0;
    }
  }
}

@include media-breakpoint-down("mobile") {
  .video-card {
    padding-left: 0;
    padding-right: 4px;
  }
  .video-card__img {
    max-height: 180px;
  }
}

</style>
