<template>
  <Dropdown
    :show="isShow"
    :indent="0"
    right
    class="profile-dropdown"
  >
    <template #trigger>
      <div
        ref="$el"
        class="header-profile"
        :class="{ active: isShow }"
        @click="handleToggle"
      >
        <h3 class="header-profile__name">
          <span> {{ firstName }} </span>
          <span v-if="secondName">&nbsp;{{ secondName }}. </span>
        </h3>
        <UserAvatar
          :avatarLink="userData.small_avatar"
          class="header-profile__avatar"
        />
      </div>
    </template>
    <div class="header-profile__dropdown-content">
      <div class="header-profile__role">
        {{ displayedRole }}
      </div>

      <router-link
        :to="{ name: names.profile }"
        class="header-profile__link"
        @click="handleClose"
      >
        {{ t("menus.profile") }}
      </router-link>
      <CleanButton
        class="header-profile__link"
        :disabled="isDisabledLogBtn || showLoader"
        @click="handleLogout"
      >
        {{ t("menus.logout") }}
      </CleanButton>
    </div>
  </Dropdown>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { names } from '@/constants/routes';
import { roles } from '@/constants/roles';
import { useClickOutside } from '@/libraries/useClickOutside';

import Dropdown from '@/components/Dropdown.vue';
import UserAvatar from '@/components/UserAvatar.vue';
import CleanButton from '@/components/CleanButton.vue';

import { logoutUser } from '@/services/userActions';
import { LoggedUser } from '@/types/user';

const store = useStore();

const $el = ref<HTMLDivElement | null>(null);
const isShow = ref(false);
const { t } = useI18n();

const emit = defineEmits<{
  (e: 'lockLogin', val: boolean): void;
}>();

const userData = computed<LoggedUser>(() => store.state.UserData.user);
const firstName = computed<string>(() => {
  if (userData.value.firstname && userData.value.firstname?.length > 16) {
    return `${userData.value.firstname?.slice(0, 16)}...`;
  }
  return userData.value.firstname || '';
});

const secondName = computed<string>(() => (userData.value.surname || '').charAt(0));

const displayedRole = computed<string>(() => {
  const roleData = userData.value.role;
  if (roleData.shortname === roles.student && !userData.value.organization) {
    return t('roles.user');
  }
  return t(`roles.${roleData.shortname}`);
});

const handleToggle = (): void => {
  isShow.value = !isShow.value;
};

const handleClose = (): void => {
  isShow.value = false;
};

const handleLogout = async (): Promise<void> => {
  disableLoginBtn();
  handleClose();
  await logoutUser();
  await store.dispatch('clearUserData');
};

const isDisabledLogBtn = ref(false);
const showLoader = computed(() => store.getters['Common/showLoader']);

const disableLoginBtn = () => {
  isDisabledLogBtn.value = true;
  emit('lockLogin', true);
};

useClickOutside($el, handleClose);
</script>

<style scoped lang="scss">
@use "sass:math";
@import "@/assets/style/include.scss";

$paddingH: 20px;

.header-profile {
  padding: 1rem $paddingH;
  position: relative;
  cursor: pointer;
  transition: $transition-speed;

  &__name + &__avatar {
    margin-left: 10px;
  }

  &.active {
    height: 100%;
    background: $colorBlue1;
    color: white;
  }
}

.header-profile__name {
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 400;
}

.header-profile__dropdown-content {
  margin: -#{math.div($base-indent, 2)} -#{math.div($base-indent, 4)};
}

.header-profile__role {
  background: $colorBlue1;
  color: white;
  font-size: 12px;
  padding: 0.25rem $paddingH;
  border-bottom: 2px solid $colorBluePressed;
}

.header-profile__link {
  cursor: pointer;
  display: block;
  padding: 12px $paddingH;
  text-align: left;
  width: 100%;

  &.active {
    background: $colorBluePressed;
    color: white;
    pointer-events: none;
  }

  &:hover {
    background: $colorBlueHover;
    color: white;
  }

  &:focus, &:active {
    background: $colorBluePressed;
  }
}


@include media-breakpoint-down("mobile") {
  .header-profile {
    padding: 0.25rem 0;

    button {
      font-size: 12px;
    }
  }

  .header-profile__name {
    display: none;
  }
}

</style>
