import { ref } from 'vue';
import {
  Centrifuge,
  ConnectedContext,
  DisconnectedContext,
  ErrorContext,
  MessageContext,
  SubscriptionTokenContext,
} from 'centrifuge';
import { AxiosResponse } from 'axios';

import axios from '@/services/axios';
import { wsMessageLog } from '@/utils/websocket';

export const ws = ref<Centrifuge>();
export const userChannel = ref<string>('');
export const sessionChannel = ref<string>('');
export const launcherChannel = ref<string>('');

export const getCentrifugeConnect = (): Promise<Record<string, string>> => axios
  .post('/broadcasting/connect')
  .then((res: AxiosResponse) => res.data.data);

export const getAuthTokenToChannel = (
  ctx: SubscriptionTokenContext
): Promise<string> => axios
  .post('/broadcasting/auth', { ...ctx })
  .then((res: AxiosResponse) => res.data.data.token);

export const websocketConnect = async (): Promise<void> => {
  const {
    baseURL,
    token,
    user_channel,
    session_channel,
    launcher_channel,
  } = await getCentrifugeConnect();

  const path = baseURL.replace('https', 'wss');
  userChannel.value = user_channel;
  sessionChannel.value = session_channel;
  launcherChannel.value = launcher_channel;

  try {
    ws.value = new Centrifuge(
      `${path}connection/websocket?cf_protocol_version=v2`,
      { token }
    );
    ws.value.on('connected', (ctx: ConnectedContext) => {
      wsMessageLog(ctx, 'Connected');
      axios.defaults.headers.common['X-Socket-Id'] = ctx.client;
    });
    ws.value.on('disconnected', (ctx: DisconnectedContext) => {
      wsMessageLog(ctx, 'Disconnected');
    });
    ws.value.on('error', (ctx: ErrorContext) => {
      wsMessageLog(ctx, 'Error');
    });
    ws.value.on('message', (ctx: MessageContext) => {
      wsMessageLog(ctx, 'Message');
    });

    ws.value.connect();
  } catch (err) {
    console.error(err);
  }
};

export default websocketConnect;
