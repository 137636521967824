<template>
  <ElSelect
    v-model="modelValue"
    placeholder=""
    :disabled="disabled"
    class="main-select"
    :class="customClass"
    @change="$emit('change', $event)"
    @focus="$emit('focus', $event)"
    @blur="$emit('blur', $event)"
  >
    <ElOption
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
  </ElSelect>
</template>

<script setup lang="ts">
import { toRef, computed } from 'vue';
import { ElSelect, ElOption } from 'element-plus';
import { SelectOption } from '@/types';

interface Props {
  value: string | number,
  options: SelectOption[],
  disabled?: boolean,
  error?: any,
}

const props = withDefaults(defineProps<Props>(), {
  value: '',
  disabled: false,
  error: null,
});

defineEmits<{
  (event: 'change', payload: any): void;
  (event: 'focus', payload: FocusEvent): void;
  (event: 'blur', payload: FocusEvent): void;
}>();

const modelValue = toRef(props, 'value');

const customClass = computed(() => ({
  'is-error': props.error,
}));

</script>

<style lang="scss">
@import "@/assets/style/include.scss";

.main-select {

  .el-select {
    height: $input-height;

    &__wrapper {
      min-height: $input-height;
      border: 1px solid;
      border: $input-border;
      border-radius: $form-border-radius;
      box-shadow: none;
      transition: $transition-speed border;

      &.is-hovering {
        border-color: $colorBlue1;
        box-shadow: none;
      }

      &.is-focus {
        border-color: $colorBlue1;
        outline: 0;
      }

      &.is-disabled {
        background-color: $color-disabled;
        cursor: auto;

        &:hover {
          border-color: $input-border-color;
        }
      }
    }
  }

  &.is-error {
    .el-select {
      &__wrapper {
        border-color: $input-border-color-error;
      }
    }
  }
}

.el-select__popper  {
  transform: translateY(-5px);

  &.el-popper {
    box-shadow: none;
    border-color: $colorBlue1;
  }

  .el-popper__arrow {
    display: none;
  }
}

.el-select-dropdown__item.is-selected {
  color: $color-primary;
}
</style>
